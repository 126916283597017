/* eslint-env browser */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import TypeCheck from "typecheck-extended";

import { CreateUser } from "../../services/UserMgmt";
import { ValidatePassword, ValidateToken } from "../../services/Auth";

import "../../assets/style.css";
import logo from "../../logo.svg";
import { Button, Input } from "../../CladUi";


function onError(rsp) {
  // TODO: Handle errors properly
  console.error("rsp: ", rsp);
}


class SignupObj extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
    this.updateLoginStatus = this.updateLoginStatus.bind(this);
  }

  onCellChange(value, key) {
    this.props.dispatch({
      type: "USER_DETAILS_UPDATE_SINGLE_VALUE",
      key,
      value: "", // temporarily disables field
    });
  }

  onSuccess() {
    const { email, password } = this.props.editFormData;
    ValidatePassword(email, password, this.updateLoginStatus);
  }

  updateLoginStatus(authenticated, userDetails) {
    TypeCheck(authenticated, "boolean");
    TypeCheck(userDetails, "object");

    // TODO: updateLoginStatus is duplicated elsewhere
    if (authenticated) {
      localStorage.setItem("iat", userDetails.jwt.iat);
      localStorage.setItem("expiresIn", userDetails.jwt.expiresIn);
      localStorage.setItem("userUuid", userDetails.uuid);
    }
    this.props.dispatch({
      type: "LOGIN_STATUS",
      authenticated,
      userDetails,
    });
  }

  submitForm() {
    if (
      !this.props.editFormData.name
      || !this.props.editFormData.email
      || !this.props.editFormData.password
    ) {
      onError("Error: Missing required field.");
      return;
    }
    CreateUser(this.props.editFormData, this.onSuccess, onError);
  }

  render() {
    const fakeJwt = {
      iat: localStorage.iat || 0,
      "expiresIn": localStorage.expiresIn || 0,
      userUuid: localStorage.userUuid || 0,
    }; // TODO: Use a real JWT
    if (ValidateToken(fakeJwt)) {
      return (<Redirect to="/getting_started" />);
    }
    return (
      <div>
        <div id="form">
          <img src={logo} alt="Fynish" width="300px" />
          {" "}
          <br />
          <b>Personal Finance Demystified</b>
          <h1>Beta Access Coming Soon... </h1>
          <em>[Soonish]</em>
          {
            this.props.statusMessage
              ? <h2>{this.props.statusMessage}</h2>
              : ""
          }
          <p>
            <span style={{ color: "rgba(100, 100, 100, 0.85)" }}>
              Name:
            </span>
            <br />
            <Input
              style={{ backgroundColor: "rgba(100, 100, 100, 0.75)" }}
              onChange={(e) => this.onCellChange(e.target.value, "name")}
              value={this.props.editFormData.name}
            />
          </p>
          <p>
            <span style={{ color: "rgba(100, 100, 100, 0.85)" }}>
              Email:
            </span>
            <br />
            <Input
              style={{ backgroundColor: "rgba(100, 100, 100, 0.75)" }}
              onChange={(e) => this.onCellChange(e.target.value, "email")}
              value={this.props.editFormData.email}
            />
          </p>
          <button
            type="button"
            disabled
            onClick={() => this.submitForm()}
          >
            Join Waitlist
          </button>
          <p>
            Already have an account?
            {" "}
            <a href="/login">Login</a>
            .
          </p>
        </div>
        <div id="footer">
          <span className="copyright">
            Background photo:
            &nbsp;
            <a href="https://www.flickr.com/photos/triplea4/15228957152/">Mountains</a>
            {" "}
            by Tony A.
            &nbsp;
            <a href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>
          </span>
        </div>
        <div id="bg" />
      </div>
    );
  }
}


SignupObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  editFormData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  statusMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => (
  {
    editFormData: state.UserDetails.editFormData,
    statusMessage: state.Auth.statusMessage,
  }
);

const Signup = connect(mapStateToProps)(SignupObj);

export default Signup;
