/* eslint-env browser */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import TypeCheck from "typecheck-extended";

import { Button, Input } from "../../CladUi";
import { ValidatePassword, ValidateToken } from "../../services/Auth";

import "../../assets/style.css";
import logo from "../../logo.svg";


class LoginObj extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failedLogin: false,
    };
    this.updateLoginStatus = this.updateLoginStatus.bind(this);
  }

  onCellChange(value, key) {
    this.props.dispatch({
      type: "LOGIN_FORM_CHANGE",
      key,
      value,
    });
  }

  onLoginAttempt() {
    const { email, password } = this.props;
    ValidatePassword(email, password, this.updateLoginStatus);
    this.setState({ failedLogin: true });
  }

  updateLoginStatus(authenticated, userDetails) {
    TypeCheck(authenticated, "boolean");
    TypeCheck(userDetails, "object");

    // TODO: updateLoginStatus is duplicated elsewhere
    if (authenticated) {
      localStorage.setItem("iat", userDetails.jwt.iat);
      localStorage.setItem("expiresIn", userDetails.jwt.expiresIn);
      localStorage.setItem("userUuid", userDetails.uuid);
    }
    this.props.dispatch({
      type: "LOGIN_STATUS",
      authenticated,
      userDetails,
    });
  }

  render() {
    const { failedLogin } = this.state;
    const fakeJwt = {
      iat: localStorage.iat || 0,
      expiresIn: localStorage.expiresIn || 0,
      userUuid: localStorage.userUuid || 0,
    }; // TODO: Use a real JWT
    if (ValidateToken(fakeJwt)) {
      return (<Redirect to="/" />);
    }
    return (
      <div>
        <div id="form">
          <img src={logo} alt="Fynish" width="300px" /> <br />
          <b>Personal Finance Demystified</b>
          <h1>Login</h1>
          {
            this.props.statusMessage ? <h2>{this.props.statusMessage}</h2> : ""
          }
          {
            failedLogin ? <h3 style={{ color: "pink" }}>Error: Invalid email or password.</h3> : ""
          }
          <p>
            email:<br />
            <Input
              onChange={e => this.onCellChange(e.target.value, "email")}
              value={this.props.email}
            />
          </p>
          <p>
            password:<br />
            <Input
              onChange={e => this.onCellChange(e.target.value, "password")}
              value={this.props.password}
              type="password"
            />
          </p>
          <Button onClick={() => this.onLoginAttempt()} rank="primary">
            Login
          </Button>
          <p>Not a user yet? <a href="/signup">Signup</a>.</p>
          </div>
        <div id="footer">
          <span className="copyright">
            Background photo:
            &nbsp;<a href="https://www.flickr.com/photos/triplea4/15228957152/">Mountains</a> by Tony A.
            &nbsp;<a href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>
          </span>
        </div>
        <div id="bg" />
      </div>
    );
  }
}

LoginObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  statusMessage: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => (
  {
    password: state.Auth.password,
    statusMessage: state.Auth.statusMessage,
    email: state.Auth.email,
  }
);

const Login = connect(mapStateToProps)(LoginObj);

export default Login;
