/* eslint-env browser */

import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import Fynish from "./Fynish";
import registerServiceWorker from "./registerServiceWorker";

ReactDOM.render(
  <Fynish />,
  document.getElementById("root"),
);
registerServiceWorker();
