const initialState = {
  articles: [
    {
      articleBody: "",
      author: "",
      category: [],
      date: "",
      image: "",
      summary: "",
      title: "",
      url: "",
      uuid: "",
    },
  ],
  metaData: {},
  rspCode: 0,
  rspText: "",
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "ARTICLES_LOAD_ALL_ARTICLES":
      return {
        ...state,
        articles: action.rsp.data,
        rspCode: action.rsp.rspCode,
        rspText: action.rsp.rspText,
      };

    case "ARTICLES_LOAD_SINGLE_ARTICLE":
      return {
        ...state,
        articles: [{ ...action.rsp.data }],
        metaData: action.rsp.metaData,
        rspCode: action.rsp.rspCode,
        rspText: action.rsp.rspText,
      };

    case "ARTICLES_RESET_STATE":
      return initialState;

    default:
      return state;
  }
}
