import { combineReducers } from "redux";
import Articles from "./scenes/Articles/reducer";
import Auth from "./scenes/Login/reducer";
import Budget from "./scenes/Budget/reducer";
import UserDetails from "./scenes/UserDetails/reducer";

const Reducer = combineReducers({
  Articles,
  Auth,
  Budget,
  UserDetails,
});

export default Reducer;
