/* eslint-env browser */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TypeCheck from "typecheck-extended";

import { Card, Column, Row } from "../../CladUi";
import { GetUser } from "../../services/UserMgmt";
import InvestmentTable from "./components/InvestmentTable";
import RetireParameters from "./components/RetireParameters";
import RetireTable from "./components/RetireTable";


function onError(msg) {
  console.error(msg);
}

class UserDetailsObj extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onSuccessUserFetch = this.onSuccessUserFetch.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
  }

  componentWillMount() {
    GetUser(this.props.userUuid, this.onSuccessUserFetch, onError);
  }

  onSuccessUserFetch(rsp) {
    TypeCheck(rsp, "object");

    this.props.dispatch({
      type: "USER_DETAILS_POPULATE",
      rsp,
    });
  }

  toggleEditMode() {
    this.props.dispatch({
      type: "USER_DETAILS_UPDATE_TOGGLE_EDIT_MODE",
    });
  }

  // TODO: This needs a loading icon while loading
  render() {
    const retireAccounts = this.props.accounts.filter((act) => act.retirement);
    const nonRetireAccounts = this.props.accounts.filter((act) => !act.retirement);
    return (
      <div>
        <Row>
          <Column>
            <Card color="tertiary">
              <InvestmentTable
                accounts={nonRetireAccounts}
                heading="Investments"
              />
            </Card>
          </Column>
          <Column>
            <Card color="tertiary">
              <InvestmentTable
                accounts={retireAccounts}
                heading="Retirement Investments"
              />
            </Card>
          </Column>
          <Column>
            <Card color="tertiary">
              <h3>Placeholder</h3>
            </Card>
          </Column>
        </Row>
        <Row>
          <Column flex="3">
            <Card>
              <RetireTable
                projectionParameters={this.props.projectionParameters}
                userProfile={this.props.userProfile}
              />
            </Card>
          </Column>
          <Column>
            <Card>
              <RetireParameters
                userProfile={this.props.userProfile}
              />
            </Card>
          </Column>
        </Row>
      </div>
    );
  }
}

// TODO: Defaults should be coming from the sitewide defauls API
UserDetailsObj.defaultProps = {
  projectionParameters: {
    annualContributionIncrease: 0.025,
    annualReturn: 0.0525,
    inflationRate: 0.025,
    monthlySpending: 0,
    lifeExpectancy: 78.6,
    retirementAge: 65,
  },
};

UserDetailsObj.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
    retirement: PropTypes.bool.isRequired,
    taxible: PropTypes.bool.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  dispatch: PropTypes.func.isRequired,
  projectionParameters: PropTypes.shape({
    annualContributionIncrease: PropTypes.number,
    annualReturn: PropTypes.number,
    inflationRate: PropTypes.number,
    monthlySpending: PropTypes.number,
    lifeExpectancy: PropTypes.number,
    retirementAge: PropTypes.number,
  }),
  userProfile: PropTypes.shape({
    birthdate: PropTypes.string,
    country: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    name: PropTypes.string,
    password: PropTypes.string,
    retireDate: PropTypes.string,
    salary: PropTypes.number,
    email: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  userUuid: PropTypes.string.isRequired,
};


const mapStateToProps = (state) => (
  {
    accounts: state.UserDetails.accounts,
    jwt: state.Auth.jwt,
    projectionParameters: state.UserDetails.projectionParameters,
    userProfile: state.UserDetails.userProfile,
    userUuid: localStorage.getItem("userUuid"),
  }
);

const UserDetails = connect(mapStateToProps)(UserDetailsObj);

export default UserDetails;
