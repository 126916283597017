import React from "react";
import PropTypes from "prop-types";

import { CurrentAge } from "../../../../services/Time";
import { RetireAge, RetireYearsDays, LastYearOfWork } from "../../../../services/RetirementProjections";


const Retirement = (props) => {
  const { birthdate, retireDate } = props.userProfile;
  const [retireYrs, retireDys] = RetireYearsDays(retireDate);
  const ageNow = CurrentAge(`${birthdate}`);
  const ageRetire = RetireAge(birthdate, retireDate);
  return (
    <div>
      <p>
        <b>Current Age: </b>
        {" "}
        {ageNow}
        {" "}
        <br />
        <b>Retirement Age: </b>
        {" "}
        {ageRetire}
        {" "}
        <br />
        <b>Retire in:</b>
        {" "}
        {`${retireYrs} years, ${retireDys} days`}
        {" "}
        <br />
        <b>Last Year of Work:</b>
        {" "}
        {LastYearOfWork(retireDate)}
        {" "}
        <br />
      </p>
    </div>
  );
};


Retirement.propTypes = {
  userProfile: PropTypes.shape({
    birthdate: PropTypes.string,
    retireDate: PropTypes.string,
  }).isRequired,
};

export default Retirement;
