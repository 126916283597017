/* eslint-env browser */
import TypeCheck from "typecheck-extended";

import FetchService from "../FetchService";

/*
 __      __             _           _   _____                   ___ _
 \ \    / /_ _ _ _ _ _ (_)_ _  __ _(_) |_   _|__ _ __  _ __    | __(_)_ __
  \ \/\/ / _` | '_| ' \| | ' \/ _` |_    | |/ -_) '  \| '_ \_  | _|| \ \ /
   \_/\_/\__,_|_| |_||_|_|_||_\__, (_)   |_|\___|_|_|_| .__(_) |_| |_/_\_\
                              |___/                   |_|
TODO: This all needs to be refactored to move to backend and be made secure.
*/


function onError(error) {
  console.error(error);
}

export const fakeJwt = {
  iat: localStorage.iat || 0,
  expiresIn: localStorage.expiresIn || 0,
  userUuid: localStorage.userUuid || 0,
}; // TODO: Fix this


export function ValidateToken(fakeToken) {
  TypeCheck(fakeToken, "object");

  const now = Date.now();
  const exp = new Date(parseInt(fakeToken.iat, 10) + parseInt(fakeToken.expiresIn, 10));
  if (now - exp < 0) {
    return true;
  }
  return false;
}


const minToMs = {
  one: 60000,
  five: 300000,
  ten: 600000,
  twenty: 1200000,
  forty: 2400000,
  eighty: 4800000,
};

function GenerateToken(msToExp = "twenty") {
  TypeCheck(msToExp, "enum", true, Object.keys(minToMs));

  const fakeDeccodedToken = {
    iat: Date.now(),
    expiresIn: minToMs[msToExp],
  };
  return fakeDeccodedToken;
}

function ReturnLoginStatus(rsp, callbackData) {
  TypeCheck(rsp, "array");
  TypeCheck(callbackData, "object");
  let userDetails = {};
  rsp.forEach((user) => {
    if (user.userProfile.email === callbackData.email) {
      userDetails = user.userProfile;
    }
  });
  let authenticated = false;
  if (userDetails.password === callbackData.password) {
    authenticated = true;
  }
  userDetails.jwt = GenerateToken();
  callbackData.callbackFunc(authenticated, userDetails);
}


function GetUserByEmail(callbackData) {
  TypeCheck(callbackData, "object");

  const uri = "http://localhost:3005/users/";
  FetchService("GET", "jwt", uri, ReturnLoginStatus, onError, null, callbackData);
}

export function ValidatePassword(email, password, callbackFunc) {
  TypeCheck(email, "string");
  TypeCheck(password, "string");
  TypeCheck(callbackFunc, "function");

  const callbackData = {
    email,
    password,
    callbackFunc,
  };
  GetUserByEmail(callbackData);
}
