/* eslint-env browser */
import TypeCheck from "typecheck-extended";

import FetchService from "../FetchService";
import User from "./components/User";

const jwt = "Warning: JWT not setup";
const baseUri = "http://localhost:3005/";

const userBudgetUri = `${baseUri}budgets/`;
const projParamsUri = `${baseUri}projectionParameters/`;
const userUri = `${baseUri}users/`;


function fakeUuid() {
  const a = Math.random().toString().split(".")[1];
  const b = Math.random().toString().split(".")[1];
  const concat = (a + b).substring(0, 32);
  const id = concat.replace(/(\d{8})(\d{4})(\d{4})(\d{4})(\d{12})/, "$1-$2-$3-$4-$5");
  return (id);
}

export function CreateUser({ email, name, password }, onSuccess, onError) {
  TypeCheck(email, "string");
  TypeCheck(name, "string");
  TypeCheck(password, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const user = new User();
  user.userProfile.email = email;
  user.userProfile.name = name;
  user.userProfile.password = password;
  const id = fakeUuid();
  user.userProfile.uuid = id;
  user.id = id;
  FetchService("POST", jwt, userUri, onSuccess, onError, user);
}

export function GetUser(uuid, onSuccess, onError) {
  TypeCheck(uuid, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const uri = userUri + uuid;
  FetchService("GET", jwt, uri, onSuccess, onError);
}

export function UpdateUser(uuid, body, onSuccess, onError) {
  TypeCheck(uuid, "string");
  TypeCheck(body, "object");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const uri = userUri + uuid;
  FetchService("PUT", jwt, uri, onSuccess, onError, body);
}

export function GetProjectionParams(uuid, onSuccess, onError) {
  TypeCheck(uuid, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  // TODO: Take into account default vs user modified projection paramaters
  FetchService("GET", jwt, projParamsUri, onSuccess, onError);
}

export function GetUserBudget(uuid, onSuccess, onError) {
  TypeCheck(uuid, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const uri = userBudgetUri + uuid;
  FetchService("GET", jwt, uri, onSuccess, onError);
}

export function UpdateUserBudget(uuid, items, onSuccess, onError) {
  TypeCheck(uuid, "string");
  TypeCheck(items, "array");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");

  const body = { items };
  const uri = userBudgetUri + uuid;
  FetchService("PUT", jwt, uri, onSuccess, onError, body);
}
