export const categoryTypes = [
  "Bill",
  "Charity",
  "Income",
  "Misc",
  "Retirement",
  "Savings",
  "Taxes",
  "Unallocated",
];

export const categoryLabelSuggestions = {
  bill: ["Housing", "Misc", "Transportation"],
  charity: ["Tithe", "Donation", "Misc"],
  income: ["Capital Gains", "Salary", "Freelance", "Misc"],
  retirement: ["IRA (Roth)", "IRA (Traditional)", "IRA (SIMPLE)", "401(K)", "Other"],
  savings: ["Emergency Fund", "Goal", "Misc"],
  taxes: ["Federal", "State", "Local", "Sales", "Other"],
};
