/*
More Currency Codes: https://en.wikipedia.org/wiki/ISO_4217
More Language Codes: http://www.lingoes.net/en/translator/langcode.htm
Also see: https://www.science.co.il/language/Locale-codes.php
*/

const supportedLocales = {
  // North America
  usa: {
    name: "United States",
    locale: "en-US",
    currency: "USD",
    currencySymbol: "$",
  },
  canada: {
    name: "Canada",
    locale: "en-CA",
    currency: "CAD",
    currencySymbol: "$",
  },
  // // EUROPE
  // andorra: {
  //   name: 'Andorra',
  //   locale: '00-AD',
  //   currency: 'EUR',
  //   currency: '€',
  // },
  austria: {
    name: "Austria",
    locale: "en-AU",
    currency: "EUR",
    currencySymbol: "€",
  },
  belgium: {
    name: "Belgium",
    locale: "fr-BE",
    currency: "EUR",
    currencySymbol: "€",
  },
  // cyprus: {
  //   name: 'Cyprus',
  //   locale: '00-CY',
  //   currency: 'EUR',
  //   currency: '€',
  // },
  estonia: {
    name: "Estonia",
    locale: "et-EE",
    currency: "EUR",
    currencySymbol: "€",
  },
  finland: {
    name: "Finland",
    locale: "se-FI",
    currency: "EUR",
    currencySymbol: "€",
  },
  france: {
    name: "France",
    locale: "fr-FR",
    currency: "EUR",
    currencySymbol: "€",
  },
  germany: {
    name: "Germany",
    locale: "de-DE",
    currency: "EUR",
    currencySymbol: "€",
  },
  greece: {
    name: "Greece",
    locale: "el-GR",
    currency: "EUR",
    currencySymbol: "€",
  },
  // guadeloupe: {
  //   name: 'Guadeloupe',
  //   locale: '00-GP',
  //   currency: 'EUR',
  //   currency: '€',
  // },
  ireland: {
    name: "Ireland",
    locale: "en-IE",
    currency: "EUR",
    currencySymbol: "€",
  },
  italy: {
    name: "Italy",
    locale: "it-IT",
    currency: "EUR",
    currencySymbol: "€",
  },
  latvia: {
    name: "Latvia",
    locale: "lv-LV",
    currency: "EUR",
    currencySymbol: "€",
  },
  liechtenstein: {
    name: "Liechtenstein",
    locale: "de-LI",
    currency: "CHF",
    currencySymbol: " ",
  },
  lithuania: {
    name: "Lithuania",
    locale: "lt-LT",
    currency: "EUR",
    currencySymbol: "€",
  },
  luxembourg: {
    name: "Luxembourg",
    locale: "de-LU",
    currency: "EUR",
    currencySymbol: "€",
  },
  malta: {
    name: "Malta",
    locale: "mt-MT",
    currency: "EUR",
    currencySymbol: "€",
  },
  // martinique: {
  //   name: 'Martinique',
  //   locale: '00-MQ',
  //   currency: 'EUR',
  //   currency: '€',
  // },
  // mayotte: {
  //   name: 'Mayotte',
  //   locale: '00-YT',
  //   currency: 'EUR',
  //   currency: '€',
  // },
  monaco: {
    name: "Monaco",
    locale: "fr-MC",
    currency: "EUR",
    currencySymbol: "€",
  },
  montenegro: {
    name: "Montenegro",
    locale: "sr-SP",
    currency: "EUR",
    currencySymbol: "€",
  },
  netherlands: {
    name: "Netherlands",
    locale: "nl-NL",
    currency: "EUR",
    currencySymbol: "€",
  },
  portugal: {
    name: "Portugal",
    locale: "pt-PT",
    currency: "EUR",
    currencySymbol: "€",
  },
  // reunion: {
  //   name: 'Réunion',
  //   locale: '00-RE',
  //   currency: 'EUR',
  //   currency: '€',
  // },
  // saintBarthelem: {
  //   name: 'Saint Barthélemy',
  //   locale: '00-BL',
  //   currency: 'EUR',
  //   currency: '€',
  // },
  // saintPierreAndMiquelon: {
  //   name: 'Saint Pierre and Miquelon',
  //   locale: '00-PM',
  //   currency: 'EUR',
  //   currency: '€',
  // },
  // sanMarino: {
  //   name: 'San Marino',
  //   locale: '00-SM',
  //   currency: 'EUR',
  //   currency: '€',
  // },
  slovakia: {
    name: "Slovakia",
    locale: "sk-SK",
    currency: "EUR",
    currencySymbol: "€",
  },
  slovenia: {
    name: "Slovenia",
    locale: "sl-SI",
    currency: "EUR",
    currencySymbol: "€",
  },
  spain: {
    name: "Spain",
    locale: "es-ES",
    currency: "EUR",
    currencySymbol: "€",
  },
  switzerland: {
    name: "Switzerland",
    locale: "fr-CH",
    currency: "CHF",
    currencySymbol: " ",
  },
  unitedKingdom: {
    name: "United Kingdom",
    locale: "en-GB",
    currency: "GBP",
    currencySymbol: "£",
  },
  // Asia
  china: {
    name: "China",
    locale: "zh-CN",
    currency: "CNY",
    currencySymbol: "¥",
  },
  japan: {
    name: "Japan",
    locale: "ja-JP",
    currency: "JPY",
    currencySymbol: "¥",
  },
  southKorea: {
    name: "South Korea",
    locale: "ko-KR",
    currency: "KRW",
    currencySymbol: "₩",
  },
  // Misc
  australia: {
    name: "Austrailia",
    locale: "en-AU",
    currency: "AUD",
    currencySymbol: "$",
  },
  israel: {
    name: "Israel",
    locale: "he-IL",
    currency: "ILS",
    currencySymbol: "₪",
  },
};

export default supportedLocales;
