import React from "react";
import PropTypes from "prop-types";
import TypeCheck from "typecheck-extended";
import { FormatCurrency } from "num-format";

import { categoryTypes } from "../../services/Constants";
import {
  Input, Table, Tbody, Thead, Td, Tr,
} from "../../../../CladUi";

import "./style.css";


function getTotals(items) {
  TypeCheck(items, "array");

  const totals = {};
  categoryTypes.forEach((cat) => {
    totals[cat] = 0;
  });
  items.forEach((item) => {
    if (typeof totals[item.categoryType] === "undefined") {
      totals[item.categoryType] = 0;
    }
    totals[item.categoryType] += item.value;
    totals.Unallocated += item.value;
  });
  return totals;
}


function drawHighlight(cat, text = "") {
  TypeCheck(cat, "string");
  TypeCheck(text, "string", false);

  return (
    <div
      className={`highlight ${cat}`}
    >
      <strong>
        {text}
      </strong>
    </div>
  );
}

const BudgetTable = (props) => {
  const { items, onItemChange, saveToDB } = props;
  const totals = getTotals(items);
  return (
    <Table>
      <Thead>
        <Tr>
          <Td>Type</Td>
          <Td>Label</Td>
          <Td>Description</Td>
          <Td>Value</Td>
        </Tr>
      </Thead>
      <Tbody>
        {
          items.map((item) => {
            const catSquare = drawHighlight(item.categoryType);
            return (
              <Tr key={item.uuid}>
                <Td>{catSquare}</Td>
                <Td>{item.categoryLabel}</Td>
                <Td>
                  <Input
                    onBlur={() => saveToDB()}
                    onChange={(e) => props.onItemChange(item.uuid, e.target.value, "description")}
                    value={item.description}
                  />
                </Td>
                <Td>
                  <Input
                    onBlur={() => saveToDB()}
                    onChange={(e) => onItemChange(item.uuid, e.target.value, "value")}
                    step="0.01"
                    type="number"
                    value={item.value}
                  />
                </Td>
              </Tr>
            );
          })
        }
        {
          categoryTypes.map((cat) => {
            const catLabel = drawHighlight(cat, cat);
            const catValue = drawHighlight(cat, FormatCurrency(totals[cat]));
            return (
              <Tr key={cat}>
                <Td colSpan="3">{catLabel}</Td>
                <Td>{catValue}</Td>
              </Tr>
            );
          })
        }
      </Tbody>
    </Table>
  );
};


BudgetTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onItemChange: PropTypes.func.isRequired,
  saveToDB: PropTypes.func.isRequired,
};


export default BudgetTable;
