import React from "react";
import PropTypes from "prop-types";
import { FormatCurrency } from "num-format";

import {
  Table, Tbody, Td, Thead, Tr,
} from "../../../../CladUi";


let total = 0;

const InvestmentTable = (props) => (
  <div>
    {
      props.accounts.forEach((act) => { total += act.balance; })
    }
    <h3>{FormatCurrency(total)}</h3>
    <strong>{props.heading}</strong>
    <Table>
      <Thead>
        <Tr>
          <Td>Account</Td>
          <Td>Type</Td>
          <Td>Balance</Td>
        </Tr>
      </Thead>
      <Tbody>
        {
          props.accounts.map((acct) => (
            <Tr key={acct.uuid}>
              <Td>{acct.accountName}</Td>
              <Td>{acct.accountType}</Td>
              <Td>{FormatCurrency(acct.balance)}</Td>
            </Tr>
          ))
        }
      </Tbody>
    </Table>
  </div>

);

InvestmentTable.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
    retirement: PropTypes.bool.isRequired,
    taxible: PropTypes.bool.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  heading: PropTypes.string.isRequired,
};

export default InvestmentTable;
