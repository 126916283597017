/* eslint-env browser */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TypeCheck from "typecheck-extended";

import { Button } from "../../CladUi";
import EditDetails from "./components/EditDetails";
import { GetUser, UpdateUser } from "../../services/UserMgmt";
import ListDetails from "./components/ListDetails";

function onError(msg) {
  console.error(msg);
}

class UserDetailsObj extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFieldEdit = this.onFieldEdit.bind(this);
    this.onSaveChanges = this.onSaveChanges.bind(this);
    this.onSaveSuccess = this.onSaveSuccess.bind(this);
    this.onSuccessUserFetch = this.onSuccessUserFetch.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
  }

  componentWillMount() {
    this.loadPage();
  }

  onSuccessUserFetch(rsp) {
    TypeCheck(rsp, "object");

    this.props.dispatch({
      type: "USER_DETAILS_POPULATE",
      rsp,
    });
  }

  onFieldEdit(value, key) {
    // TypeCheck(value, ['number', 'string']); // TODO: Waiting on new TypeCheck feature release
    TypeCheck(key, "string");

    this.props.dispatch({
      type: "USER_DETAILS_UPDATE_SINGLE_VALUE",
      key,
      value,
    });
  }

  onSaveChanges() {
    const {
      accounts,
      annualContributions,
      editFormData,
      projectionParameters,
    } = this.props;
    const body = {
      accounts,
      annualContributions,
      projectionParameters,
      userProfile: editFormData,
    };
    UpdateUser(editFormData.uuid, body, this.onSaveSuccess, onError);
  }

  onSaveSuccess() {
    this.toggleEditMode();
    this.loadPage();
  }

  loadPage() {
    GetUser(this.props.userUuid, this.onSuccessUserFetch, onError);
  }

  toggleEditMode() {
    this.props.dispatch({
      type: "USER_DETAILS_UPDATE_TOGGLE_EDIT_MODE",
    });
  }

  render() {
    return (
      <div>
        <h2>User Details:</h2>
        {
          this.props.editMode
            ? (
              <div>
                <Button onClick={this.toggleEditMode}>
                  Cancel
                </Button>
                <Button onClick={this.onSaveChanges} rank="primary">
                  Save Changes
                </Button>
                <EditDetails
                  onFieldEdit={this.onFieldEdit}
                  editFormData={this.props.editFormData}
                />
              </div>
            )
            : (
              <div>
                <Button onClick={this.toggleEditMode} rank="primary">
                  Edit Details
                </Button>
                <ListDetails
                  userDetails={this.props.userProfile}
                />
              </div>
            )
        }
      </div>
    );
  }
}


UserDetailsObj.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  annualContributions: PropTypes.objectOf(PropTypes.number).isRequired,
  dispatch: PropTypes.func.isRequired,
  userUuid: PropTypes.string.isRequired,
  editFormData: PropTypes.shape({
    birthdate: PropTypes.string,
    country: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    name: PropTypes.string,
    password: PropTypes.string,
    retireDate: PropTypes.string,
    salary: PropTypes.number,
    email: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  editMode: PropTypes.bool.isRequired,
  projectionParameters: PropTypes.objectOf(PropTypes.number).isRequired,
  userProfile: PropTypes.shape({
    birthdate: PropTypes.string,
    country: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    name: PropTypes.string,
    password: PropTypes.string,
    retireDate: PropTypes.string,
    salary: PropTypes.number,
    email: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => (
  {
    accounts: state.UserDetails.accounts,
    annualContributions: state.UserDetails.annualContributions,
    editFormData: state.UserDetails.editFormData,
    editMode: state.UserDetails.editMode,
    jwt: state.Auth.jwt,
    userUuid: localStorage.getItem("userUuid"),
    userProfile: state.UserDetails.userProfile,
    projectionParameters: state.UserDetails.projectionParameters,
  }
);

const UserDetails = connect(mapStateToProps)(UserDetailsObj);

export default UserDetails;
