class User {
  constructor() {
    this.accounts = [];
    this.annualContributions = {
      retirement: null,
      savings: null,
    };
    this.projectionParameters = {
      annualContributionIncrease: null,
      annualReturn: null,
      inflationRate: null,
      lifeExpectancy: null,
      monthlySpending: null,
      retirementAge: null,
    };
    this.userProfile = {
      birthdate: "",
      country: "",
      currency: "",
      locale: "",
      name: "",
      password: "",
      retireDate: "",
      salary: 0,
      email: "",
      uuid: "",
    };
    this.id = "";
  }
}

export default User;
