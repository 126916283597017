const initialState = {
  editFormData: {
    birthdate: "",
    country: "",
    currency: "",
    locale: "",
    name: "",
    password: "",
    retireDate: "",
    salary: 0,
    email: "",
    uuid: "",
  },
  editMode: false,
  // - - - - - -
  accounts: [],
  annualContributions: {},
  projectionParameters: {
    annualContributionIncrease: 0,
    annualReturn: 0,
    inflationRate: 0,
    lifeExpectancy: 0,
    monthlySpending: 0,
    retirementAge: 0,
  },
  userProfile: {
    birthdate: "",
    country: "",
    currency: "",
    locale: "",
    name: "",
    password: "",
    retireDate: "",
    salary: 0,
    email: "",
    uuid: "",
  },
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_DETAILS_POPULATE": {
      return {
        ...state,
        editFormData: { ...action.rsp.userProfile },
        ...action.rsp,
      };
    }

    case "USER_DETAILS_PROJ_PARAMS_POPULATE":
      return {
        ...state,
        projectionParameters: { ...action.projectionParameters },
      };

    case "USER_DETAILS_UPDATE_SINGLE_VALUE": {
      const editFormData = { ...state.editFormData };
      editFormData[action.key] = action.value;
      return {
        ...state,
        editFormData,
      };
    }

    case "USER_DETAILS_UPDATE_TOGGLE_EDIT_MODE": {
      let editFormData = { ...initialState.editFormData };
      if (!state.editMode) { // if false user is about to enter edit mode
        editFormData = { ...state.userProfile };
      }
      return {
        ...state,
        editMode: !state.editMode,
        editFormData,
      };
    }

    default:
      return state;
  }
}
