import TypeCheck from "typecheck-extended";

// date params are compatible with mm-dd-yyy and yyyy-mm-dd

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function Today() {
  return new Date();
}

export function DateParse(date, filter, format = "number") {
  TypeCheck(date, "string");
  TypeCheck(filter, "enum", true, ["year", "month", "day"]);
  TypeCheck(format, "enum", false, ["number", "text"]);

  const parsedDate = new Date(date);
  switch (filter) {
    case "year":
      if (format === "number") {
        return parsedDate.getFullYear();
      }
      return "Error: Year \"text\" formmating not supported. Please use \"number\"";
    case "month":
      if (format === "number") {
        return parsedDate.getMonth() + 1;
      }
      return months[parsedDate.getMonth()];
    case "day":
      if (format === "number") {
        return "Error: Day \"number\" formmating not supported. Please use \"number\"";
      }
      return days[parsedDate.getDay()];
    default:
      return "Error: Proccessing Date";
  }
}

export function TimeSpan(startDate, endDate) {
  TypeCheck(startDate, "string", false);
  TypeCheck(endDate, "string");

  let start = new Date();
  if (startDate !== "now") {
    start = new Date(startDate);
  }

  let end = new Date();
  if (endDate !== "now") {
    end = new Date(endDate);
  }
  const msPerDay = 1000 * 60 * 60 * 24;
  const daysSpan = (end - start) / msPerDay;
  const daysPerYear = 365.25;
  let years = Math.floor(daysSpan / daysPerYear);
  let fullDays = Math.floor(daysSpan % daysPerYear);

  if (fullDays === 365) {
    years += 1;
    fullDays = 0;
  }

  return [years, fullDays];
}

export function CurrentAge(birthday) {
  TypeCheck(birthday, "string");

  const birthdate = new Date(birthday);
  const today = new Date();
  let years = (today.getFullYear() - birthdate.getFullYear());
  if (
    today.getMonth() < birthdate.getMonth()
    || (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate())
  ) {
    years -= 1;
  }
  return years;
}
