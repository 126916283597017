import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { GetArticles } from "../../../../services/Articles";
import ArticleListing from "./components/ArticleListing";
import { Loading } from "../../../../CladUi";

class ListingsObj extends Component {
  constructor() {
    super();
    this.loadArticles = this.loadArticles.bind(this);
  }

  componentWillMount() {
    GetArticles(this.loadArticles);
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: "ARTICLES_RESET_STATE",
    });
  }

  loadArticles(rsp) {
    this.props.dispatch({
      type: "ARTICLES_LOAD_ALL_ARTICLES",
      rsp,
    });
  }

  render() {
    return (
      <div>
        { this.props.rspCode === 0 ? <p><Loading inverse /></p> : "" }
        {
          this.props.rspCode === 200
            ? (
              <div>
                {
                this.props.articles.map((article) => (
                  <ArticleListing
                    author={article.author}
                    category={article.category}
                    date={article.date}
                    image={article.image}
                    key={article.uuid}
                    summary={article.summary}
                    title={article.title}
                    url={article.url}
                    uuid={article.uuid}
                  />
                ))
              }
              </div>
            )
            : (
              <div>
                <h2>
                  Error
                  {this.props.rspCode}
                </h2>
                <p>{this.props.rspText}</p>
              </div>
            )
        }
      </div>
    );
  }
}

ListingsObj.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({
    author: PropTypes.string,
    category: PropTypes.arrayOf(PropTypes.string),
    date: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    uuid: PropTypes.string,
  })).isRequired,
  rspCode: PropTypes.number.isRequired,
  rspText: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => (
  {
    articles: state.Articles.articles,
    rspCode: state.Articles.rspCode,
    rspText: state.Articles.rspText,
  }
);

const Listings = connect(mapStateToProps)(ListingsObj);

export default Listings;
