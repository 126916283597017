import React from "react";
import { Link } from "react-router-dom";

import { NavBar } from "../../CladUi";

const menuItems = [
  {
    name: "Home",
    uri: "/",
  },
  {
    name: "Dashboard",
    uri: "/dash",
  },
  {
    name: "Settings",
    uri: "/user",
  },
  {
    name: "Budget",
    uri: "/budget",
  },
  {
    name: "Articles",
    uri: "/articles",
  },
  {
    name: "Theme Ex.",
    uri: "/theme",
  },
];


const Navigation = () => (
  <NavBar>
    {
      menuItems.map((item, i) => (
        <span key={item.name}>
          <Link to={item.uri}>{item.name.toUpperCase()}</Link>
          { (i < menuItems.length - 1) ? <span>&nbsp; | &nbsp;</span> : "" }
        </span>
      ))
    }
  </NavBar>
);

export default Navigation;
