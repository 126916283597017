const initialState = {
  items: [],
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    //
    case "BUDGET_POPULATE_ITEMS":
      return {
        ...state,
        items: [...action.items],
      };

    case "BUDGET_EDIT_ITEM":
      return {
        ...state,
        items: [...action.items],
      };

    default:
      return state;
  //
  }
}
