/* eslint-env browser */
/* eslint-disable no-underscore-dangle */

import React from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter, Redirect, Route, Switch,
} from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";

import "./App.css";
import "./CladUi/global_styles.css";
import "./CladUi/theme/cladUiTheme.css";

import logo from "./logo.svg";
import { ValidateToken } from "./services/Auth"; // TODO: This is temp.
import Navigation from "./components/Navigation";
import Reducer from "./Reducer";

import Articles from "./scenes/Articles";
import Budget from "./scenes/Budget";
import Dashboard from "./scenes/Dashboard";
import GettingStarted from "./scenes/GettingStarted";
import Landing from "./scenes/Landing";
import Login from "./scenes/Login";
import NotFound404 from "./scenes/NotFound404";
import Signup from "./scenes/Signup";
import ThemeExample from "./CladUi";
import UserDetails from "./scenes/UserDetails";


const store = createStore(
  Reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), // TODO: Remove this
);


const fakeJwt = {
  iat: localStorage.iat || 0,
  expiresIn: localStorage.expiresIn || 0,
  userUuid: localStorage.userUuid || 0
}; // TODO: Fix This

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      ValidateToken(fakeJwt)
        ? <Component {...props} />
        : <Redirect to={{ pathname: "/login" }} />
    )}
  />
);


PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};


const Fynish = () => (
  <Provider store={store}>
    <BrowserRouter>
      <div className="App">
        <header className="App-header">
          <a href="/"><img src={logo} className="App-logo" alt="logo" /></a>
          <h1 className="App-title">Personal Finance Demystified</h1>
        </header>
        { ValidateToken(fakeJwt) ? <Navigation /> : "" }
        <Switch>
          <Route exact path="/" component={Login} />
          <PrivateRoute path="/articles" component={Articles} />
          <PrivateRoute path="/budget" component={Budget} />
          <PrivateRoute path="/dash" component={Dashboard} />
          <PrivateRoute path="/getting_started" component={GettingStarted} />
          <Route exact path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <PrivateRoute path="/theme" component={ThemeExample} />
          <PrivateRoute path="/user" component={UserDetails} />
          <Route component={NotFound404} />
        </Switch>
      </div>
    </BrowserRouter>
  </Provider>
);

export default Fynish;
