/* eslint-env browser */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TypeCheck from "typecheck-extended";

import { GetUserBudget, UpdateUserBudget } from "../../services/UserMgmt";

import BudgetTable from "./components/BudgetTable";


function onError(msg) {
  console.error(msg); // TODO: Actually Handle this error
}

function onSuccess() {
  // TODO: This should be a green check or something for the user
  console.warn("Provide \"Saved\" Feedback");
}

export function updateBudgetItem(items, itemUuid, newValue, fieldUpdated) {
  TypeCheck(items, "array");
  TypeCheck(itemUuid, "string");
  // TypeCheck(newValue, 'number OR string');  // TODO: Fix oneOfType issue

  const itemsCopy = [];
  items.forEach((item) => {
    if (item.uuid === itemUuid) {
      const itemCopy = { ...item };
      itemCopy[fieldUpdated] = newValue;
      itemsCopy.push(itemCopy);
    } else {
      itemsCopy.push(item);
    }
  });
  return itemsCopy;
}

class BudgetObj extends Component {
  constructor(props) {
    super(props);
    this.onItemChange = this.onItemChange.bind(this);
    this.saveToDB = this.saveToDB.bind(this);
    this.onSuccessBudgetFetch = this.onSuccessBudgetFetch.bind(this);
  }


  componentWillMount() {
    this.getBudget();
  }


  onSuccessBudgetFetch(rsp) {
    TypeCheck(rsp, "object");

    this.props.dispatch({
      type: "BUDGET_POPULATE_ITEMS",
      items: rsp.items,
    });
  }

  onItemChange(itemUuid, newValue, fieldChanged) {
    TypeCheck(itemUuid, "string");
    TypeCheck(newValue, "string");

    let parsedValue = newValue;
    if (fieldChanged === "value") {
      parsedValue = Number.parseFloat(newValue, 10) || 0;
    }
    const { items } = this.props;
    this.props.dispatch({
      type: "BUDGET_EDIT_ITEM",
      items: updateBudgetItem(items, itemUuid, parsedValue, fieldChanged),
    });
  }

  getBudget() {
    GetUserBudget(this.props.userUuid, this.onSuccessBudgetFetch, onError);
  }

  saveToDB() {
    const { items, userUuid } = this.props;
    UpdateUserBudget(userUuid, items, onSuccess, onError);
  }


  render() {
    return (
      <div>
        <h2>Budget</h2>
        <BudgetTable
          items={this.props.items}
          saveToDB={this.saveToDB}
          onItemChange={this.onItemChange}
        />
      </div>
    );
  }
}


BudgetObj.propTypes = {
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    categoryType: PropTypes.string,
    categoryLabel: PropTypes.string,
    descripton: PropTypes.string,
    value: PropTypes.number,
    uuid: PropTypes.string,
  })).isRequired,
  userUuid: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => (
  {
    items: state.Budget.items,
    jwt: state.Auth.jwt,
    userUuid: localStorage.getItem("userUuid"),
  }
);

const Budget = connect(mapStateToProps)(BudgetObj);

export default Budget;
