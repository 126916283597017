const article = {
  authorUuid: "aDefault-Auth-orFy-nish-43207b254fed",
  category: ["banking"],
  date: "27 November 2017",
  image: null,
  summary: "Are you getting the best deal with your bank? If your bank doesn't offer Kasasa, it's likely you are not.",
  title: "What is Kasasa",
  url: "/what-is-kasasa/",
  uuid: "article-2017-1127-801e-3d3c3f958be4",
  body: `
_**kasasa**_ |’ka • sä • sə| adjective
A trademarked, brand name used by credit unions and community banks to describe checking and savings account services.

---

It’s rare to hear consumers utter the phrase, “I love my bank!” Even rarer still is, “Look at the great rates I get on my checking account!” In today’s low interest rate environment, most big banks are paying almost zero interest on checking and savings accounts and are charging consumers a litany of fees. For those that are currently paying for checking or savings accounts, there is a better way.

There is really nothing special about Kasasa. It is not a revolutionary new service or financial invention. It is simply a brand and does what any brand name does. Any financial institution could easily offer services that exactly match those found in Kasasa, but the brand name allows for less consumer effort in discovering these services when searching for a new credit union or community bank. The main take away is that the big banks generally both charge more fees and offer far less competitive interest rates, so a great way to be charged less and paid more is to find a financial institution near you offering Kasasa.

The specific rates, limits, and requirements below are accurate at the time we wrote this article (November 2017), but are subject to change for better or for worse. Please read the account details before opening an account with any financial institution.

---
## Kasasa Cash Checking
• Free Checking Account
• Interest on Account Balance [ 3.25% APY on balances up to $15,000 ]
• Refunds on Fees Charged for Using Other Banks ATMs [ $25/month max refund ]

In order to qualify for the highest interest rate, there are a few monthly requirements:
• At least 1 direct deposit or ACH payment transaction
• At least 12 debit card purchases
• Be enrolled in and agree to receive e-statements

The checking account is still free whether or not the monthly qualification requirements are met. Customers with existing checking accounts are probably already doing those things, but it is important to note that a much lower interest rate is paid for any month during which the requirements are not met.

This account pays its highest interest rate on the first $15,000 of account balance and a lower rate on any amount above that. However, be aware of the potential danger of keeping large amounts of money in a checking account. It is very important that account holders know how they will react to a large checking balance. A large balance can easily derail a saving/investing plan by triggering thinking such as, “I have plenty of money in my account, so I can afford to buy this thing I’ve been wanting.” If maintaining a high balance leads to greater spending, the “free checking” account will actually end up costing more in the long run than an account that pays no interest.

For account holders than plan to keep low checking balances, there’s another free checking option that pays cash back on purchases instead of the account balance. We found these accounts to have much lower limits for the maximum amount that can be earned each year and recommend Kasasa Cash Checking for most people.

---
## Free Kasasa Cash Back Checking
• Free Checking Account
• Cash Back on Purchases [ 3.00% Cash Back. Max: $9/month, $108/year ]
• Refunds Fees Charge for Using Other Banks ATMs [ $25/month max refund ]

In order to qualify for the high interest rate, there are a few monthly requirements:
• At least 1 direct deposit or ACH payment transaction
• At least 12 debit card purchases
• Be enrolled in and agree to receive e-statements

Both of the above free checking accounts may be paired with a free savings account.

---
## Kasasa Saver
• Free Account
 • Interest on Account Balance [ 1.01% APY on balances up to $50,000 ]

In order to qualify for the highest interest rate each month, the Kasasa Saver account must be linked to a Kasasa Cash or Kasasa Cash Back checking account that meets the monthly qualifications for that account.

---
##### Fynish is solely responsible for this editorial content. It is not endorsed or paid for by Kasasa, LTD.
`,
};

export default article;
