import React from "react";
// import PropTypes from 'prop-types';
import { Route } from "react-router-dom";

import Article from "./components/Article";
import Listings from "./components/Listings";


const Articles = () => (
  <div>
    <Route exact path="/articles" component={Listings} />
    <Route
      path="/articles/:article"
      render={({ match }) => (
        <Article urlMatch={match.params.article} />
      )}
    />
  </div>
);

export default Articles;
