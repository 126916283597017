import React from "react";

import birdBlankSignImg from "./assets/404-graphicV2.png";


const leftColStyle = { float: "left", marginRight: "3%", width: "22%" };
const rightColStyle = { float: "right", padding: "7% 22% 5% 0%", width: "44%" };

const NotFound404 = () => (
  <div>
    <div style={leftColStyle}>
      <img src={birdBlankSignImg} alt="" style={{ width: "100%" }} />
    </div>
    <div style={rightColStyle}>
    <h2>
      Error 404 - Page Not Found
     </h2>
     <p>
       Everything is still new here. We&rsquo;re in Beta, so things are still going to be rough
       around the edges until we officially launch.
     </p>
     <p>
       For now, we recommend double checking the url you were trying to visit. If you feel like
       there is an error on our part, we would love for you to fill out a bug report.
       (Kinga loves squashing bugs!)
     </p>
    </div>
  </div>
);

export default NotFound404;
