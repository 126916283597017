import TypeCheck from "typecheck-extended";

import irasTraditionalVsRoth from "../TestingData/Articles/iras-traditional-vs-roth";
import simpleIra from "../TestingData/Articles/simple-ira";
import whatIsKasasa from "../TestingData/Articles/what-is-kasasa";


/*
 __      __  ___   ___
 \ \    / / |_ _| | _ \
  \ \/\/ /   | |  |  _/
   \_/\_/   |___| |_|
  TODO: This works as-is, but it should actually fetch articles from a DB.
*/

const articleBaseUri = "/articles";

export function GetArticles(onSuccess) {
  TypeCheck(onSuccess, "function");

  const rsp = {
    data: [],
    metaData: {},
    rspCode: 200,
    rspText: "OK",
  };

  const allArticles = [irasTraditionalVsRoth, simpleIra, whatIsKasasa];

  allArticles.forEach((article) => {
    const currentArticle = {
      articleBody: article.body || "",
      author: article.authorUuid || "", // TODO: authorUuid should be translated to authorName
      category: article.category || [],
      date: article.date || "",
      summary: article.summary || "",
      image: article.image || "/graphics/no_image.jpg",
      title: article.title || "",
      url: articleBaseUri + article.url || "",
      uuid: article.uuid || "",
    };
    rsp.data.push(currentArticle);
  });

  onSuccess(rsp);
}


function selectArticle(articleName) {
  TypeCheck(articleName, "string");

  switch (articleName) {
    case "iras-traditional-vs-roth": {
      return [irasTraditionalVsRoth, 200, "OK"];
    }

    case "simple-ira": {
      return [simpleIra, 200, "OK"];
    }

    case "what-is-kasasa": {
      return [whatIsKasasa, 200, "OK"];
    }

    default:
      return [{}, 404, `No Article Found Named: "${articleName}".`];
  }
}


export function GetArticle(articleName, onSuccess) {
  TypeCheck(articleName, "string");
  TypeCheck(onSuccess, "function");

  const [article, rspCode, rspText] = selectArticle(articleName);

  const rsp = {
    data: {
      articleBody: article.body || "",
      author: article.authorUuid || "", // TODO: authorUuid should be translated to authorName
      category: article.category || [],
      date: article.date || "",
      summary: article.summary || "",
      image: article.image || "/graphics/no_image.jpg",
      title: article.title || "",
      url: articleBaseUri + article.url || "",
      uuid: article.uuid || "",
    },
    metaData: {},
    rspCode,
    rspText,
  };
  onSuccess(rsp);
}
