import React from "react";
import PropTypes from "prop-types";

// TODO: Use a CladUI element instead of all this hardcoded CSS.
// TODO: Fix image float escaping div.

const ArticleListing = (props) => (
  <>
    <div style={{ clear: "both", margin: "3%" }}>
      <div style={{ float: "left", margin: "0.5% 2% 0.5% 0.5%" }}>
        <a href={props.url}>
          <img src={props.image} style={{ width: "200px", height: "100px" }} alt={props.title} />
        </a>
      </div>
      <a href={props.url}><b>{props.title}</b></a>
      {" "}
      <br />
      <p>
        {props.summary}
        {" "}
        <br />
        <a href={props.url}>Read</a>
      </p>
    </div>
    <hr />
  </>
);

ArticleListing.propTypes = {
  // author: PropTypes.string.isRequired,
  // category: PropTypes.arrayOf(PropTypes.string).isRequired,
  // date: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  // uuid: PropTypes.string.isRequired,
};


export default ArticleListing;
