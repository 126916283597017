import TypeCheck from "typecheck-extended";

import { FormatCurrency } from "num-format";
import {
  CurrentAge, DateParse, TimeSpan, Today,
} from "../Time";


export function LastYearOfWork(retireDate) {
  TypeCheck(retireDate, "string");

  return DateParse(retireDate, "year");
}


export function ProjectionsByYear({
  annualContribution,
  annualContributionIncrease,
  annualReturn,
  birthdate,
  currentRetirementBalance,
  inflationRate,
  lifeExpectancy,
  monthlySpending,
  retirementDate,
}) {
  TypeCheck(annualContribution, "number");
  TypeCheck(annualContributionIncrease, "number");
  TypeCheck(annualReturn, "number");
  TypeCheck(birthdate, "string");
  TypeCheck(currentRetirementBalance, "number");
  TypeCheck(inflationRate, "number");
  TypeCheck(lifeExpectancy, "number");
  TypeCheck(monthlySpending, "number");
  TypeCheck(retirementDate, "string");

  /*
    Expected Return (per year) = [
      Yr. Age,
      Nom. Spend/Mth,
      Adj. Spend/Mth,
      Adj. Spend/Yr.,
      Contribution/Yr,
      Nom. Ret. Bal.
    ];
  */

  let year = DateParse(`${Today()}`, "year");
  let age = CurrentAge(`${birthdate}`);
  let nominalRetirementBal = currentRetirementBalance;
  let adjustedMonthlySpending = monthlySpending;
  let retirementContributionYear = annualContribution;
  let adjustedAnnualSpending = monthlySpending * 12;

  const retireYear = LastYearOfWork(retirementDate);
  // const numOfRowsToDraw = (parseInt(retireYear, 10) - parseInt(year, 10)) + 1;
  const numOfRowsToDraw = (lifeExpectancy - age) + 1;
  const yearlyProjections = [];

  for (let i = 0; i < numOfRowsToDraw; i += 1) {
    if (i > 0) {
      year += 1;
      age += 1;
      adjustedMonthlySpending *= (1 + inflationRate);
      adjustedAnnualSpending *= (1 + inflationRate);

      if (retireYear >= year) {
        retirementContributionYear *= (1 + annualContributionIncrease);
        // TODO: Adjust nominalRetirementBal for compounding deposit schedule
        nominalRetirementBal *= (1 + annualReturn);
        nominalRetirementBal += retirementContributionYear;
      } else {
        retirementContributionYear = 0;
        // TODO: Adjust nominalRetirementBal for compounding deposit schedule
        if (nominalRetirementBal > 0) {
          nominalRetirementBal *= (1 + annualReturn);
        }
        nominalRetirementBal -= adjustedAnnualSpending;
      }
    }

    const currentRows = [
      year,
      age,
      FormatCurrency(monthlySpending),
      FormatCurrency(adjustedMonthlySpending),
      FormatCurrency(adjustedAnnualSpending),
      FormatCurrency(retirementContributionYear),
      FormatCurrency(nominalRetirementBal),
    ];
    yearlyProjections.push(currentRows);
  }

  return yearlyProjections;
}

// TODO: This needs tests.
export function RetireYearsDays(retireDate) {
  TypeCheck(retireDate, "string");

  const [retireYrs, retireDys] = TimeSpan("now", retireDate);
  return [retireYrs, retireDys];
}


export function RetireAge(birthdate, retireDate) {
  TypeCheck(birthdate, "string");
  TypeCheck(retireDate, "string");

  const [years, days] = TimeSpan(birthdate, retireDate);
  let retireAge = parseInt(years, 10);
  if (days) {
    retireAge += parseFloat((days / 365.25).toFixed(1));
  }

  return retireAge;
}
