const article = {
  authorUuid: "aDefault-Auth-orFy-nish-43207b254fed",
  category: ["investing"],
  date: "19 November 2017",
  image: null,
  summary: "SIMPLE IRAs explained. How is it different from ROTH IRAs or Traditional IRAs? What are the contribution limits?",
  title: "What is a SIMPLE IRA",
  url: "/simple-ira/",
  uuid: "article-2017-1119-a7aa-76bb0e29f007",
  body: `
A SIMPLE IRA is an employer sponsored retirement plan that shares some similarities to both the Traditional IRA and the 401K.

The name SIMPLE IRA comes from the government backronym **S**avings **I**ncentive **M**atch **PL**an for Employees. While the name is a bit of a stretch, the plan itself provides an easy way for small businesses to offer retirement plans for their employees.

Employers match the individual’s contributions to the plan at a dollar for dollar rate for a total match up to 3% of the employee’s compensation.*

The SIMPLE IRA is funded through employee payroll deductions. Contributions to the SIMPLE IRA are tax-deductible, and all taxes are deferred until withdrawals are made during retirement. The IRS mandates that minimum withdrawals be made at age 70½.

The maximum an employee can contribute to a plan is $12,500, but total annual contributions can not exceed total annual income. This contribution limit only applies to the SIMPLE IRA plan and only to the employee’s contributions. The employer’s match is a dollar for dollar match (up to 3% of the employee’s salary) on top of the employee contributions. After reaching the SIMPLE IRA contribution limit, the employee may still make the maximum allowed contributions to a [Traditional or Roth IRA](http://fynish.com/investing/iras-traditional-vs-roth/) as those limits are calculated separately.

Employee is always 100% vested in (or, has ownership of) all SIMPLE IRA money. However, there is a two year waiting period on all newly created SIMPLE IRA before the account holder may not withdraw money or roll-over the account into a different type of IRA. During this period the penalty for withdrawing funds before retirement is increased from 10% to 25%. That is in addition to the tax that is already do on withdrawn funds. See Using Retirement Money Before Retiring.


_* Employers can opt to lower the amount of the match for 2 out 5 years in voluntary plans, or set the match rate permanently at 2% in non-elective plans. Rules can change. See the IRS website for the most up-to-date information._

_[1] irs.gov - 18 Nov. 2017 - [SIMPLE IRA Plan])(https://www.irs.gov/retirement-plans/plan-sponsor/simple-ira-plan)_

---
##### Fynish is not a tax or investment firm and can not provide investment or tax advice. Please consult certified tax and investment professionals before making tax or investment decisions.
`,
};

export default article;
