import React from "react";
import PropTypes from "prop-types";


const ListDetails = (props) => (
  <div>
    <p>
      <b>accounts:</b>
      {" "}
      [not listed]
      <br />
      <b>annualContributions:</b>
      {" "}
      [not listed]
      <br />
      <b>birthdate:</b>
      {" "}
      {props.userDetails.birthdate}
      <br />
      <b>currency:</b>
      {" "}
      {props.userDetails.currency}
      <br />
      <b>name:</b>
      {" "}
      {props.userDetails.name}
      <br />
      <b>password:</b>
      {" "}
      {props.userDetails.password}
      <br />
      <b>scheduled retirement:</b>
      {" "}
      {props.userDetails.retireDate}
      <br />
      <b>salary:</b>
      {" "}
      {props.userDetails.salary}
      <br />
      <b>uuid:</b>
      {" "}
      {props.userDetails.uuid}
      <br />
      <b>email:</b>
      {" "}
      {props.userDetails.email}
      <br />
    </p>
  </div>
);

ListDetails.propTypes = {
  // TODO: Remove duplicated propTypes. The import isn't working, so this is duplicated here.
  userDetails: PropTypes.shape({
    birthdate: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    retireDate: PropTypes.string.isRequired,
    salary: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
};

export default ListDetails;
