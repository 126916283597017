import React from "react";
import PropTypes from "prop-types";

import { Input, Option, Select } from "../../../../CladUi";
import Locales from "../../../../services/Locales";

const EditDetails = (props) => (
  <div>
    <p>
      email:
      {" "}
      <br />
      {" "}
      {/* TODO: Email change requires special treatment */}
      <Input
        onChange={(e) => props.onFieldEdit(e.target.value, "email")}
        value={props.editFormData.email}
      />
      <br />

      name:
      {" "}
      <br />
      <Input
        onChange={(e) => props.onFieldEdit(e.target.value, "name")}
        value={props.editFormData.name}
      />
      <br />

      password:
      {" "}
      <br />
      {" "}
      {/* TODO: Password change requires special treatment */}
      <Input
        onChange={(e) => props.onFieldEdit(e.target.value, "password")}
        value={props.editFormData.password}
      />
      <br />

      birthdate:
      {" "}
      <br />
      <Input
        type="date"
        onChange={(e) => props.onFieldEdit(e.target.value, "birthdate")}
        value={props.editFormData.birthdate}
      />
      <br />

      salary:
      {" "}
      <br />
      <Input
        type="Number"
        onChange={(e) => props.onFieldEdit(Number.parseInt(e.target.value, 10), "salary")}
        value={props.editFormData.salary}
      />
      <br />
    </p>

    <h2>Site Settings</h2>

    Change Currency:
    {" "}
    <br />
    { /* TODO: Only saves new 'country' value. Not updated: 'locale' or 'currency' */ }
    <Select
      onChange={(e) => props.onFieldEdit(e.target.value, "country")}
      value={props.editFormData.country}
    >
      {
        Object.keys(Locales).map((country) => {
          const { currency, currencySymbol, name } = Locales[country];
          const concatenated = `(${currencySymbol}) ${currency} - ${name}`;
          return (<Option key={concatenated} value={country}>{concatenated}</Option>);
        })
      }
    </Select>

  </div>
);


// TODO: Remove duplicated propTypes. The import isn't working, so this is duplicated here.
EditDetails.propTypes = {
  onFieldEdit: PropTypes.func.isRequired,
  editFormData: PropTypes.shape({
    birthdate: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    retireDate: PropTypes.string.isRequired,
    salary: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
};

export default EditDetails;
