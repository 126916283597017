import React from "react";
import PropTypes from "prop-types";

import {
  Table, Tbody, Td, Thead, Tr,
} from "../../../../CladUi";
import { ProjectionsByYear } from "../../../../services/RetirementProjections";


const headers = [
  "Yr.",
  "Age",
  "Nom. Spend/Mth",
  "Adj. Spend/Mth",
  "Adj. Spend/Yr.",
  "Contribution/Yr",
  "Nom. Ret. Bal.",
];


const RetireTable = (props) => (
  <div style={{ backgroundColor: "white" }}>
    <Table>
      <Thead>
        <Tr>
          {
            headers.map((heading) => <Td key={heading}>{heading}</Td>)
          }
        </Tr>
      </Thead>
      <Tbody>
        {
          ProjectionsByYear({
            annualContribution: 18211, // TODO: props.annualContributions.retirement
            annualContributionIncrease: props.projectionParameters.annualContributionIncrease,
            annualReturn: props.projectionParameters.annualReturn,
            birthdate: props.userProfile.birthdate,
            currentRetirementBalance: 65000, // TODO: add to json-server mock schema
            inflationRate: props.projectionParameters.inflationRate,
            lifeExpectancy: props.projectionParameters.lifeExpectancy,
            monthlySpending: props.projectionParameters.monthlySpending,
            retirementDate: props.userProfile.retireDate,
          }).map((year) => (
            <Tr key={year}>
              {
                year.map((value, i) => <Td key={`${year}_${headers[i]}`}>{value}</Td>)
              }
            </Tr>
          ))
        }
      </Tbody>
    </Table>
  </div>
);


RetireTable.propTypes = {
  projectionParameters: PropTypes.shape({
    annualContributionIncrease: PropTypes.number.isRequired,
    annualReturn: PropTypes.number.isRequired,
    inflationRate: PropTypes.number.isRequired,
    monthlySpending: PropTypes.number.isRequired,
    lifeExpectancy: PropTypes.number.isRequired,
    retirementAge: PropTypes.number.isRequired,
  }).isRequired,
  userProfile: PropTypes.shape({
    birthdate: PropTypes.string,
    country: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    name: PropTypes.string,
    password: PropTypes.string,
    retireDate: PropTypes.string,
    salary: PropTypes.number,
    email: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
};

export default RetireTable;
