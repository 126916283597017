const article = {
  authorUuid: "aDefault-Auth-orFy-nish-43207b254fed",
  category: ["investing"],
  date: "19 November 2017",
  image: null,
  summary: "Learn the difference between these two retirement investment options and how one might be better for you than the other. ",
  title: "Traditional vs Roth IRAs",
  url: "/iras-traditional-vs-roth/",
  uuid: "article-2017-1119-8f18-9605d19ef9c4",
  body: `
Traditional and Roth IRAs are Individual Retirement Accounts. These are accounts you setup yourself (not employer sponsored) with the financial institution of your choice.

The current maximum contribution for both Traditional and Roth IRAs is $5,500. If you are 50 or older, you are allowed to contribute $6,500. Your annual contributions may not exceed your annual taxable income. The limit is for the combined contributions to both Traditional and Roth IRAs. [1]

**For example:**

> Mortimer is 47 and contributes 5,500 to his Roth IRA. He has reached his contribution limit for the year and may not make any contributions to a Traditional IRA.

> Eliza is 51 and has both a Traditional IRA and a Roth IRA account. She contributes 5,500 to her Roth IRA. Because of her age, her total contribution limit for the year is $6,500. She is able to make an additional $1,000 contribution to her Traditional IRA before maxing out her total allowed contributions.


| CONSIDERATIONS  |  TRADITIONAL  |  ROTH |
| --------------  |  -----------  |  ---- |
| Withdraw Earnings Before Retirement           |  w/ penalty  |  w/ penalty  |
| Return Withdrawn Earnings                     |  No          |  No          |
| Withdraw Principal Before Retirement          |  w/ penalty  |  Yes         |
| Return Withdrawn Principal                    |  No          |  w/ restrictions |
| Tax Credit for Contributions                  |  Yes         |  No          |
| Tax Free Retirement Withdrawals               |  No          |  Yes         |
| Tax Free Trades within the Account            |  Yes         |  Yes         |
| Required Minimum Distributions at Age 70½     |  Yes         |  No          |
| Contributions Forbidden after Age 70½         |  Yes         |  No          |


See [SIMPLE IRAs](http://fynish.com/investing/simple-ira/) for more information on the employer sponsored plan with a similar name.

---

_[1] irs.gov - 18 Nov. 2017 - [IRA Contribution Limits](https://www.irs.gov/retirement-plans/plan-participant-employee/retirement-topics-ira-contribution-limits)_

##### Fynish is not a tax or investment firm and can not provide investment or tax advice. Please consult certified tax and investment professionals before making tax or investment decisions.
`,
};

export default article;
