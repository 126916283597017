import TypeCheck from "typecheck-extended";

/*
  This service used throuout app in lieu of fetch().
*/
export default function FetchService(
  method,
  jwt,
  url,
  onSuccess,
  onError,
  body = null,
  callbackData = null,
) {
  TypeCheck(method, "string");
  TypeCheck(jwt, "string");
  TypeCheck(url, "string");
  TypeCheck(onSuccess, "function");
  TypeCheck(onError, "function");
  TypeCheck(body, "object", false);
  TypeCheck(callbackData, "object", false); // Returned (unprocessed) to success/error callbacks.

  // TODO: When available, use GlobalError service on all errors below. [GH #1327]

  const validMethods = ["DELETE", "GET", "PATCH", "POST", "PUT"];
  if (!validMethods.includes(method)) {
    throw new Error(`FetchService Error: (${method}) is not a valid REST Method`);
  }

  const fetchParams = {
    method,
    headers: {
      Authorization: jwt,
      "Content-Type": "application/json",
    },
  };

  if (body) {
    if (method === "GET") {
      throw new Error("FetchService Error: Body not allowed on GET Requests");
    }
    fetchParams.body = JSON.stringify(body);
  }

  fetch(url, fetchParams)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        return response.json();
      }
      throw response;
    })
    .then((data) => {
      onSuccess(data, callbackData);
    })
    .catch((error) => {
      onError(error, callbackData);
    });
}
