/* eslint-env browser */
const initialState = {
  authenticated: false,
  password: "",
  status: "",
  statusMessage: "",
  email: "",
  uuid: "",
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN_FORM_CHANGE":
      return {
        ...state,
        [action.key]: action.value,
      };

    case "LOGIN_STATUS": {
      let statusMessage = "Error: Incorrect email or password";
      if (action.authenticated) {
        statusMessage = "Success: You are now logged in.";
      }
      return {
        ...state,
        authenticated: action.authenticated,
        statusMessage,
        ...action.userDetails,
      };
    }

    default:
      return state;
  }
}
