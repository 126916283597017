import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Markdown } from "react-showdown";

import { GetArticle } from "../../../../services/Articles";
import { Loading } from "../../../../CladUi";

class ArticleObj extends Component {
  constructor() {
    super();
    this.loadArticle = this.loadArticle.bind(this);
  }

  componentWillMount() {
    GetArticle(this.props.urlMatch, this.loadArticle);
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: "ARTICLES_RESET_STATE",
    });
  }

  loadArticle(rsp) {
    this.props.dispatch({
      type: "ARTICLES_LOAD_SINGLE_ARTICLE",
      rsp,
    });
  }

  render() {
    return (
      <div>
        { this.props.rspCode === 0 ? <p><Loading inverse /></p> : "" }
        {
          this.props.rspCode === 200
            ? (
              <div>
                <h2>{ this.props.title }</h2>
                <b>Author:</b>
                {" "}
                { this.props.author }
                <br />
                <b>Date:</b>
                {" "}
                { this.props.date }
                <br />
                <b>Category:</b>
                {" "}
                { [...this.props.category] }
                <br />
                <Markdown markup={this.props.articleBody} tables />
              </div>
            )
            : (
              <div>
                <h2>
                  Error
                  {this.props.rspCode}
                </h2>
                <p>{this.props.rspText}</p>
              </div>
            )
        }
      </div>
    );
  }
}

ArticleObj.propTypes = {
  articleBody: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  category: PropTypes.arrayOf(PropTypes.string).isRequired,
  date: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  rspCode: PropTypes.number.isRequired,
  rspText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  urlMatch: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => (
  {
    articleBody: state.Articles.articles[0].articleBody,
    author: state.Articles.articles[0].author,
    category: state.Articles.articles[0].category,
    date: state.Articles.articles[0].date,
    rspCode: state.Articles.rspCode,
    rspText: state.Articles.rspText, // TODO: Don't Display raw rspText to user
    title: state.Articles.articles[0].title,
  }
);

const Article = connect(mapStateToProps)(ArticleObj);

export default Article;
